import _ from 'lodash';
import React, { Component } from 'react';
import { Loader, Container, Dropdown, Button, Grid } from 'semantic-ui-react';
import { Table, Form, FormGroup, Modal, Navbar } from 'react-bootstrap';
import moment from 'moment'
import config from '../config/index'
import { connect } from 'react-redux';
import {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    resetLinkMessageQueue,
    getHistory,
    getCommands,
    sendDataLbox,
    getReferences,
    getSensores,
    unlinkLBox
} from '../redux/actions';

class Pruebas extends Component {
    state = {
        history: [],
        vehicles: [],
        commands: [],
        selectedVehicle: null,
        refreshing: false,
        loadingHistory: false,
        types: [
            { text: 'Todos', value: 0 }, { text: "Operativos", value: 1 }
        ],
        cleaningMsgQueue: false,
        showReferences: false,
        showSecureCondition: false,
        gettingReferences: false,
        n1max: null,
        n1min: null,
        n2max: null,
        n2min: null,
        a1max: null,
        a1min: null,
        a2max: null,
        a2min: null,
        contador: null,
        gettingSensores: false,
        showSensoresModal: false,
        sendingSecureCondition: false,
        updatingReferences: false,
        unLinkingLBox: false

    }
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        if (!token) {
            alert('Debes de iniciar sesion')
            this.props.history.push('/#')
        }
        else {
            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
            const { account, username, password, getLboxs, getCommands } = this.props;
            await getLboxs(account, username, password);
            await getCommands(account, username, password);

            let aux = [];

            if (this.props.lboxs.length > 0) {

                this.props.lboxs.map(
                    lbox => {
                        aux.push({ text: lbox.objectno, value: lbox });
                    }
                )
            }
            this.setState({ loading: false, vehicles: aux, commands: this.props.commands });
        }
    }
    onSelectedLBox(lbox) {
        this.setState({ selectedVehicle: lbox, history: [] })
        this.searchHistory(lbox);

    }
    filterForText(text) {
        if (text === "") {

            this.setState({ commands: this.props.commands })
        }
        const newArray = this.props.commands.filter(
            item => {
                const textData = String(text).toUpperCase();
                const commandName = String(item.nombre).toUpperCase();

                if (commandName.indexOf(textData) !== -1) {
                    return commandName.indexOf(textData) > -1
                }
            }
        );



        this.setState({ commands: newArray, data: text });


    };
    async searchHistory(lbox) {
        const { selectedVehicle, startDate, endDate, searchDateOption } = this.state
        const { account, password, username, } = this.props;

        let obj = lbox;
        if (obj) {
            this.setState({ refreshing: true, history: [] });
            let startDateUtc = ''
            let endDateUtc = ''
            let todayStart = moment().format('YYYY-MM-DD 00:00:00');
            let todayEnd = moment().format('YYYY-MM-DD 23:59:59');
            startDateUtc = moment(todayStart, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
            endDateUtc = moment(todayEnd, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
            await this.props.getHistory(account, username, password, obj.objectno, obj.deviceid, startDateUtc, endDateUtc);
            if (lbox) {
                let newSelectedLbox = _.find(this.props.lboxs, { objectno: lbox.objectno });
                this.setState({ selectedVehicle: newSelectedLbox });
            }
            this.setState({ refreshing: false, history: this.props.history })
            if (this.props.message) {
                alert(this.props.message)
            }
        } else {
            alert("No se ha seleccionado un vehículo")
        }






    }
    renderRows() {

        if (this.state.history.length > 0) {
            return this.state.history.map(
                item => {

                    return (
                        <tr>
                            <td>{item.fecha + " " + item.hora}</td>
                            <td>{item.latitud ? <a href={`http://www.google.com/maps/?q=${(item.latitud / 1000000)},${(item.longitud / 1000000)}`} target="_blank" >{(item.latitud / 1000000) + "," + (item.longitud / 1000000)}</a> : "Sin posición"}</td>
                            <td>{item.odometro ? item.odometro : 'Sin odometro'}</td>
                            <td>{item.aux_data}</td>
                        </tr>
                    );
                }
            )
        } else {
            return (
                <tr>
                    <td>No se encontraron mensajes</td>
                </tr>
            );

        }
    }
    async onButtonClick(data) {
        this.setState({ sending: true });
        const { account, username, password, sendDataLbox } = this.props;
        const { objectno, deviceid } = this.state.selectedVehicle;
        await sendDataLbox(account, username, password, objectno, deviceid, data);
        this.setState({ sending: false });
        alert(this.props.message);

    }
    renderCommands() {
        if (this.state.sending) {
            return (
                <Container style={styles.loader}>
                    <Loader size='large' active inline='centered'>Enviando comando...</Loader>
                </Container>
            );
        }
        if (this.state.commands.length > 0) {

            return this.state.commands.map(command => {
                return (
                    <Button disabled={this.state.selectedVehicle ? false : true} onClick={() => this.onButtonClick(command.base64)} style={{ width: '28%', marginTop: 10 }}>{command.nombre}</Button>
                )
            })


        } else {
            return (
                <h2>Sin comandos</h2>
            )
        }
    }
    async clearLinkMessageQueue() {
        this.setState({ cleaningMsgQueue: true });
        const { account, username, password, resetLinkMessageQueue } = this.props;
        await resetLinkMessageQueue(account, username, password, this.state.selectedVehicle.objectno);
        alert(this.props.message);
        this.setState({ cleaningMsgQueue: false });
    }
    async getReferences() {
        this.setState({ gettingReferences: true });
        const { account, username, password, getReferences, sendDataLbox } = this.props;
        const { objectno, deviceid } = this.state.selectedVehicle;
        await sendDataLbox(account, username, password, objectno, deviceid, '**R');
        await this.sleep(2000);
        await sendDataLbox(account, username, password, objectno, deviceid, '**i**r**i');
        await this.sleep(20000);
        await getReferences(account, username, password, objectno);
        if (this.props.message) {
            this.setState({ gettingReferences: false });
            alert(this.props.message);
        }
        if (this.props.references) {
            const { cables } = this.props.references.fabrica;
            this.setState({
                n1max: cables[0].valor,
                n1min: cables[1].valor,
                n2max: cables[2].valor,
                n2min: cables[3].valor,
                a1max: cables[4].valor,
                a1min: cables[5].valor,
                a2max: cables[6].valor,
                a2min: cables[7].valor,
                contador: cables[8].valor,
            })
        }
        this.setState({ gettingReferences: false, showReferences: true, });

    }
    onChangeReferenceValue(id, value) {
        switch (id) {
            case 'n1max':
                this.setState({ n1max: value });
                break;
            case 'n1min':
                this.setState({ n1min: value });
                break;
            case 'n2max':
                this.setState({ n2max: value });
                break;
            case 'n2min':
                this.setState({ n2min: value });
                break;
            case 'a1max':
                this.setState({ a1max: value });
                break;
            case 'a1min':
                this.setState({ a1min: value });
                break;
            case 'a2max':
                this.setState({ a2max: value });
                break;
            case 'a2min':
                this.setState({ a2min: value });
                break;
            case 'contador':
                this.setState({ contador: value });
                break;
        }
    }
    renderLBoxReferences() {
        if (this.props.references) {
            if (this.props.references.lbox.cables) {
                return this.props.references.lbox.cables.map(
                    cable => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', width: '23%' }}>
                                <span style={{ width: '100%', fontSize: 16, fontWeight: '800', textAlign: 'center' }} >{cable.nombre}</span>
                                <span style={{ width: '100%', fontSize: 16, textAlign: 'center' }} >{cable.valor}</span>
                            </div>
                        );
                    }
                )
            }
        }
    }
    renderReferencesValues() {
        if (this.props.references) {
            return this.props.references.fabrica.cables.map(

                cable => {

                    return (
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 5 }}>
                            <span style={{ width: '60%', fontSize: 16, textAlign: 'center' }} >{cable.nombre}</span>
                            <span style={{ width: '15%', fontSize: 16, textAlign: 'center' }}>{cable.valor}</span>
                            <Form.Control style={{ width: '15%' }} type="text" onChange={(e) => this.onChangeReferenceValue(cable.id, e.target.value)} defaultValue={cable.valor} />
                        </div>
                    );

                }
            );
        }
    }
    async updateReferences() {
        this.setState({ updatingReferences: true });
        const { n1max, n1min, n2max, n2min, a1max, a1min, a2max, a2min, contador } = this.state;
        let n1MinCommand = `g1${String(Math.round(Number(n1min) / 4)).padStart(3, 0)}k`;
        let n1MaxCommand = `g2${String(Math.round(Number(n1max) / 4)).padStart(3, 0)}k`;
        let n2MinCommand = `g3${String(Math.round(Number(n2min) / 4)).padStart(3, 0)}k`;
        let n2MaxCommand = `g4${String(Math.round(Number(n2max) / 4)).padStart(3, 0)}k`;
        let a1MinCommand = `g5${String(Math.round(Number(a1min) / 4)).padStart(3, 0)}k`;
        let a1MaxCommand = `g6${String(Math.round(Number(a1max) / 4)).padStart(3, 0)}k`;
        let a2MinCommand = `g7${String(Math.round(Number(a2min) / 4)).padStart(3, 0)}k`;
        let a2MaxCommand = `g8${String(Math.round(Number(a2max) / 4)).padStart(3, 0)}k`;
        let contCommand = `g9${String(Math.round(Number(contador) / 4)).padStart(3, 0)}k`;
        console.log(n1MinCommand, n1MaxCommand, n2MinCommand, n2MaxCommand, a1MinCommand, a1MaxCommand, a2MinCommand, a2MaxCommand, contCommand);
        this.setState({ updatingReferences: false });


    }
    renderRefencesModal() {
        let fechaUtc = this.props.references ? moment(this.props.references.fabrica.fecha, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss UTC') : '';
        let timez = this.props.references ? new Date(fechaUtc).toString() : null;
        return (
            <Modal
                show={this.state.showReferences}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => this.setState({ showReferences: false })}

            >

                <Modal.Header>
                    <h5>Referencias de la unidad  {this.state.selectedVehicle ? this.state.selectedVehicle.objectno : ''}</h5>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: 17, width: '60%' }} >{this.props.references ? 'Fecha de referencias:  ' + moment(timez).format('DD-MM-YYYY HH:mm:ss') : ''}</span>
                        <Button
                            loading={this.state.gettingReferences}
                            onClick={() => this.getReferences()}
                            style={{ backgroundColor: config.colors.green, color: 'white' }}
                        >
                            <img style={{ width: 12, height: 12, marginRight: 5 }} src={`http://168.235.83.246/images/refresh.png`} />
                            Actualizar
                        </Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} >
                        <span>Referencias LBox: </span>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '90%' }} >
                            {this.renderLBoxReferences()}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 15 }}>
                            <span style={{ width: '60%', fontSize: 16, fontWeight: '800', textAlign: 'center' }} >Nombre del cable</span>
                            <span style={{ width: '15%', fontSize: 16, fontWeight: '800', textAlign: 'center' }}>Valor actual</span>
                            <span style={{ width: '15%', fontSize: 16, fontWeight: '800', textAlign: 'center' }}>Nuevo valor</span>
                        </div>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }} >
                            {this.renderReferencesValues()}
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.setState({ showReferences: false })} >Cancelar</Button>
                    <Button
                        loading={this.state.deleting}
                        onClick={() => { this.updateReferences() }}
                        style={{ color: 'white', backgroundColor: config.colors.blue }}
                    >
                        Guardar


                    </Button>
                </Modal.Footer>
            </Modal>

        );
    }
    async saveSecureCondition() {
        this.setState({ sendingSecureCondition: true });
        const { account, username, password, sendDataLbox, sensores } = this.props;
        const { objectno, deviceid } = this.state.selectedVehicle;
        let n1 = null;
        let n2 = null;
        let a1 = null;
        let a2 = null;
        sensores.sensores.map(
            sensor => {
                if (sensor.id === "a1") {
                    a1 = sensor.valor
                }
                else if (sensor.id === "a2") {
                    a2 = sensor.valor
                }
                else if (sensor.id === "n1") {
                    n1 = sensor.valor
                }
                else if (sensor.id === "n2") {
                    n2 = sensor.valor
                }

            }
        );
        console.log(`gENTRA${n1 + n2 + a1 + a2}k`);
        this.setState({ sendingSecureCondition: false });

    }
    async onGetSensores() {
        this.setState({ gettingSensores: true });
        const { account, username, password, getSensores, sendDataLbox } = this.props;
        const { objectno, deviceid } = this.state.selectedVehicle;
        await sendDataLbox(account, username, password, objectno, deviceid, '**i**e**i');
        await this.sleep(15000);
        await getSensores(account, username, password, objectno);
        if (this.props.message) {
            alert(this.props.message);
            this.setState({ gettingSensores: false })
        } else {
            this.setState({ gettingSensores: false, showSensoresModal: true })
        }

    }
    renderSensoresValues() {
        if (this.props.sensores) {
            return this.props.sensores.sensores.map(

                sensor => {

                    return (
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 5 }}>
                            <span style={{ width: '60%', fontSize: 16, textAlign: 'center' }} >{sensor.nombre}</span>
                            <span style={{ width: '25%', fontSize: 16, textAlign: 'center' }}>{sensor.modo}</span>
                        </div>
                    );

                }
            );
        }
    }
    renderSensoresModal() {
        let fechaUtc = this.props.sensores ? moment(this.props.sensores.fecha, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss UTC') : '';
        let timez = this.props.sensores ? new Date(fechaUtc).toString() : null;
        return (
            <Modal
                show={this.state.showSensoresModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => this.setState({ showSensoresModal: false })}

            >

                <Modal.Header>
                    <h5>Referencias de la unidad  {this.state.selectedVehicle ? this.state.selectedVehicle.objectno : ''}</h5>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: 17, width: '60%' }} >{this.props.sensores ? 'Fecha de los sensores:  ' + moment(timez).format('DD-MM-YYYY HH:mm:ss') : ''}</span>
                        <Button
                            loading={this.state.gettingSensores}
                            onClick={() => this.onGetSensores()}
                            style={{ backgroundColor: config.colors.green, color: 'white' }}
                        >
                            <img style={{ width: 12, height: 12, marginRight: 5 }} src={`http://168.235.83.246/images/refresh.png`} />

                            Actualizar
                        </Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 15 }}>
                            <span style={{ width: '60%', fontSize: 16, fontWeight: '800', textAlign: 'center' }} >Nombre del sensor</span>
                            <span style={{ width: '25%', fontSize: 16, fontWeight: '800', textAlign: 'center' }}>Modo</span>
                        </div>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }} >
                            {this.renderSensoresValues()}
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.setState({ showSensoresModal: false })} >Cancelar</Button>
                    <Button
                        loading={this.state.deleting}
                        onClick={() => { this.saveSecureCondition() }}
                        style={{ color: 'white', backgroundColor: config.colors.blue }}
                    >
                        Guardar


                    </Button>
                </Modal.Footer>
            </Modal>

        );
    }
    async onUnlinkLbox() {
        this.setState({ unLinkingLBox: true });
        const { account, username, password, unlinkLBox } = this.props;
        const { objectno } = this.state.selectedVehicle;
        await unlinkLBox(account, username, password, objectno);
        this.setState({ unLinkingLBox: false });
        alert(this.props.message);

    }
    render() {
        if (this.state.vehicles.length > 0) {

            let fechaUtc = this.state.selectedVehicle ? moment(this.state.selectedVehicle.fecha, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss UTC') : '';
            let timez = this.state.selectedVehicle ? new Date(fechaUtc).toString() : null;
            return (
                <div style={styles.container}>
                    <div style={styles.form}>
                        <h2>Pruebas de instalación.</h2>
                        <Form style={{ width: '100%', marginTop: 20 }} inline>
                            <Form.Label style={{ width: '40%', marginRight: '2%', fontSize: 17 }}>Selecciona un vehículo:</Form.Label>
                            <Dropdown
                                style={{ width: '55%' }}
                                selection
                                search
                                options={this.state.vehicles}
                                onChange={(e, data) => this.onSelectedLBox(data.value)}
                            />

                        </Form>
                        <div style={styles.label}>
                            <h2>Información</h2>
                        </div>
                        <div style={styles.informationContainer}>
                            <div style={styles.infoSubContainer}>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Dispositivo:</span>
                                    <span style={{ width: '55%', fontSize: 17 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.objectno : ''}</span>
                                </div>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Device id:</span>
                                    <span style={{ width: '55%', fontSize: 15 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.deviceid : ''}</span>
                                </div>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Serie LBox:</span>
                                    <span style={{ width: '55%', fontSize: 17 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.serieLBOX : ''}</span>
                                </div>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Firmware:</span>
                                    <span style={{ width: '55%', fontSize: 17 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.firmwareVersion : ''}</span>
                                </div>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '47%' }}>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Estado:</span>
                                    <span style={{ width: '55%', fontSize: 17 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.modo : ''}</span>
                                </div>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Reporte:</span>
                                    <span style={{ width: '55%', fontSize: 16 }}>{this.state.selectedVehicle ? moment(timez).format('DD-MM-YYYY HH:mm:ss') : ''}</span>
                                </div>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Webfleet:</span>
                                    <span style={{ width: '55%', fontSize: 16 }}>{this.state.selectedVehicle  ? this.state.selectedVehicle.sincronizado === "1" ? 'Sincronizado' : 'Desincronizado'   : ''}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '98%', justifyContent: 'space-between', alignSelf: 'center' }}>
                            <Button disabled={this.state.selectedVehicle ? false : true} fluid loading={this.state.unLinkingLBox} onClick={() => this.onUnlinkLbox()} style={{ marginTop: 20, backgroundColor: 'red', color: 'white' }} >Desvincular LBox</Button>
                            <Button disabled={this.state.selectedVehicle ? false : true} fluid loading={this.state.cleaningMsgQueue} onClick={() => this.clearLinkMessageQueue()} style={{ marginTop: 20, backgroundColor: config.colors.blue, color: 'white' }} >Limpiar cola de msj</Button>
                        </div>
                        <div style={styles.label}>
                            <h2>Comandos</h2>
                        </div>
                        <div style={styles.commandContainer}>
                            <Form.Control style={styles.commandSearchbar} type="text" placeholder="Buscar comando" onChange={(e) => this.filterForText(e.target.value)} />
                            {/* <Dropdown
                                        placeholder='Tipo'
                                        style={{ width: '30%',color:'white', backgroundColor:config.colors.blue }}
                                        selection
                                        options={this.state.types} /> */}
                        </div>




                        <div style={styles.commandButtonsContainer}>{this.renderCommands()}</div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '98%', justifyContent: 'flex-end', alignSelf: 'center' }}>
                            <Button loading={this.state.gettingReferences} disabled={this.state.selectedVehicle ? false : true} fluid onClick={() => this.getReferences()} style={{ marginTop: 20, backgroundColor: config.colors.blue, color: 'white' }} >Ver referencias</Button>
                            <Button loading={this.state.gettingSensores} disabled={this.state.selectedVehicle ? false : true} fluid onClick={() => this.onGetSensores()} style={{ marginTop: 20, backgroundColor: config.colors.blue, color: 'white' }} > Condición segura </Button>
                        </div>
                    </div>
                    {this.state.loadingHistory ?
                        <Container style={styles.loaderHistory}>
                            <Loader size='massive' active inline='centered'  >Cargando historial...</Loader>
                        </Container>
                        :
                        <div style={styles.tableContainer}>
                            <div style={styles.headerTable} >
                                <span style={{ fontSize: 25, color: 'white' }} >Historial</span>
                                <Button loading={this.state.refreshing} style={{ backgroundColor: config.colors.green, color: 'white' }}  onClick={() => this.searchHistory(this.state.selectedVehicle)} >
                                    <img style={{ width: 12, height: 12, marginRight: 5 }} src={`http://168.235.83.246/images/refresh.png`} />
                                    Actualizar
                    </Button>
                            </div>
                            <Table responsive striped bordered hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%' }}>
                                <thead style={{ backgroundColor: '#E2DDDD' }}>
                                    <th>
                                        Fecha
                    </th>
                                    <th>
                                        Posición
                    </th>
                                    <th>
                                        Odómetro
                    </th>
                                    <th>
                                        Mensaje recibido
                    </th>
                                </thead>
                                <tbody style={{ backgroundColor: 'white' }}>
                                    {this.renderRows()}
                                </tbody>
                            </Table>
                        </div>

                    }
                    {this.renderRefencesModal()}
                    {this.renderSensoresModal()}
                </div>

            )
        } else {
            return (
                <Container style={styles.loader}>
                    <Loader size='large' active inline='centered'>Cargando vehículos...</Loader>
                </Container>
            )
        }
    }

}

const styles = {
    loader: {
        marginTop: 100
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        height: '92%',
        backgroundColor: config.colors.lightGray, padding: 20
    },
    form: {
        padding: 20,

        width: '40%',
        backgroundColor: 'white'
    },
    table: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        margin: 10,
        width: '60%',
        backgroundColor: 'white'
    },
    searchbar: {
        widthflex: 1,
        flexDirection: 'row',
        display: 'flex',
        height: 60,
        padding: 10,
        backgroundColor: config.colors.strongGray,
        width: '100%'
    },
    label: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 20
    },
    headerTable: {
        display: 'flex',
        width: '100%',
        padding: 5,
        backgroundColor: '#717188',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableContainer: {
        display: 'flex',
        height: '100%',
        width: '70%',
        marginLeft: 15,
        flexDirection: 'column'
    },
    searchButton: {
        backgroundColor: config.colors.blue,
        color: 'white',
        marginTop: window.innerHeight > 1000 ? 5 : 30
    },
    refreshButton: {
        backgroundColor: config.colors.green,
        color: 'white',
        marginLeft: 45
    }, loaderHistory: {
        width: '70%',
        backgroundColor: config.colors.lightGray,
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        alignSelf: 'center',
    }, informationContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: 10,
        justifyContent: 'space-between'
    },
    infoSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '47%'
    },
    infoRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    }, infoSubTitle: {
        width: '40%',
        fontSize: 16,
        color: 'lightgray'
    },
    commandContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10, flex: 1
    },
    commandSearchbar: {
        width: '100%',
        marginRight: 15,
        height: 38
    },
    commandButtonsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        height: '15%',
        overflow: 'auto',
        justifyContent: 'space-between'
    }

}



const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        lboxs: state.lbox.lboxs,
        commands: state.lbox.commands,
        history: state.lbox.history,
        message: state.lbox.message,
        references: state.lbox.references,
        sensores: state.lbox.sensores
    };
}
export default connect(mapStateToProps,
    {
        getLboxs,
        onAccountChanged,
        onUsernameChanged,
        onPasswordChanged,
        getHistory,
        getCommands,
        sendDataLbox,
        resetLinkMessageQueue,
        getReferences,
        getSensores,
        unlinkLBox
    })(Pruebas);